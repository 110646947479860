<template>
  <v-container>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card :elevation="hover ? 24 : 3" rounded>
          <v-row class="ma-2" dense v-if="switch1">
            <v-col cols="3" class="mt-4">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date1"
                    label="Fecha Inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date1"
                  @input="menu1 = false"
                  locale="es"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" class="mt-4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date2"
                    label="Fecha Termino"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date2"
                  @input="menu2 = false"
                  locale="es"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="2" class="text-center mt-4">
              <v-btn
                rounded
                text
                color="warning"
                @click="filtroLibro(date1, date2)"
              >
                Filtrar
              </v-btn>
            </v-col>
            <!-- <v-col cols="2" class="text-center mt-4" >
              <v-btn rounded text color="green" @click="obtenerTodos">
                Todos
              </v-btn>
            </v-col> -->
            <v-col cols="2" class="text-center mt-4">
              <v-btn color="purple" rounded text @click="LibroDiarioPDF"
                >PDF</v-btn
              >
            </v-col>
            <!-- <v-col v-if="openPdf == 2" cols="2" class="text-center mt-4">
              <v-btn color="purple" rounded text @click="LibroDiarioPDF"
                >PDF</v-btn
              >
            </v-col> -->
            <!-- </v-card-actions> -->
          </v-row>
          <div v-if="cargandoFiltros">
            <v-progress-linear
              indeterminate
              color="cyan"
            ></v-progress-linear>
          </div>
        </v-card>
      </template>
    </v-hover>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card :elevation="hover ? 24 : 3" class="mt-6" rounded>
          <v-card-title primary-title> Libro Diario </v-card-title>
          <v-expansion-panels popout v-model="panel" hover>
            <template>
              <v-expansion-panel v-for="dato in datos" :key="dato.voucher">
                <v-expansion-panel-header @click="getVoucher(dato.voucher)">
                  <v-row no-gutters dense>
                    <v-col cols="3" class="text-center">
                      Voucher: {{ dato.voucher }}
                    </v-col>
                    <v-col cols="3" class="text-center">
                      {{ dato.date }}
                    </v-col>
                    <v-col cols="3" class="text-center">
                      <v-chip color="teal lighten-1" outlined>
                        {{ dato.tipo_cuenta }}</v-chip
                      >
                    </v-col>
                    <v-col cols="3" v-if="dato.estado == 1">
                      <v-tooltip top color="orange">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            medium
                            dark
                            v-bind="attrs"
                            v-on="on"
                            color="warning"
                            class="mr-4"
                            @click="EditLibro(dato.voucher, dato.tipo_cuenta)"
                          >
                            mdi-pencil
                          </v-icon>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip top color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            medium
                            dark
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                            @click="DeleteLibro(dato.voucher)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Anular</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="3" v-else>
                      <v-chip color="red" dark class="mr-2" outlined>
                        Anulado</v-chip
                      >
                      <v-tooltip top color="purple">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            medium
                            dark
                            v-bind="attrs"
                            v-on="on"
                            color="purple"
                            class="mr-4"
                            @click="UndoLibro(dato.voucher)"
                          >
                            mdi-sync
                          </v-icon>
                        </template>
                        <span>Recuperar</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="text-center">
                    <v-progress-circular
                      :size="70"
                      :width="7"
                      v-if="load"
                      indeterminate
                      color="blue"
                    ></v-progress-circular>
                  </div>
                  <v-row justify="space-around" no-gutters dense v-if="!load">
                    <v-col cols="5" align="center">
                      <v-chip class="ma-2" color="light-green" dark
                        >DEBE</v-chip
                      >
                      <v-col v-for="asiento in asientos_debe" :key="asiento.id">
                        <v-row>
                          <v-col class="pa-0" cols="6">
                            <h5 class="text-left">
                              {{ asiento.cuenta.nombre_cuenta }} :
                            </h5>
                          </v-col>
                          <v-col class="pa-0" cols="6">
                            <h3 class="text-right">
                              ${{ asiento.valorformato }}
                            </h3>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-col>
                    <v-col cols="1" align="center">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col cols="5" align="center">
                      <v-chip class="ma-2" color="deep-purple lighten-2" dark
                        >HABER</v-chip
                      >
                      <v-col
                        v-for="asiento in asientos_haber"
                        :key="asiento.id"
                      >
                        <v-row>
                          <v-col class="pa-0" cols="6">
                            <h5 class="text-left">
                              {{ asiento.cuenta.nombre_cuenta }} :
                            </h5>
                          </v-col>
                          <v-col class="pa-0" cols="6">
                            <h3 class="text-right">
                              ${{ asiento.valorformato }}
                            </h3>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row justify="space-around" no-gutters v-if="!load">
                    <v-col cols="5" align="center">
                      <v-row>
                        <v-col class="pa-0 pl-3" cols="6">
                          <h5 class="text-left">TOTAL :</h5>
                        </v-col>
                        <v-col class="pa-0 pr-3" cols="6">
                          <h3 class="text-right">${{ total_debe }}</h3>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1" class="pa-0" align="center">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col cols="5" align="center">
                      <v-row>
                        <v-col class="pa-0 pl-3" cols="6">
                          <h5 class="text-left">TOTAL :</h5>
                        </v-col>
                        <v-col class="pa-0 pr-3" cols="6">
                          <h3 class="text-right">${{ total_haber }}</h3>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
          <v-card-actions>
            <v-row class="ma-2" dense>
              <v-col cols="6" align="center">
                <v-row>
                  <v-col class="pa-0 pl-8" cols="6">
                    <h5 class="text-left">Total Debe:</h5>
                  </v-col>
                  <v-col class="pa-0 pr-10" cols="6">
                    <h3 class="text-right">${{ sumaTotDebe }}</h3>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" align="center">
                <v-row>
                  <v-col class="pa-0 pl-8" cols="6">
                    <h5 class="text-left">Total Haber:</h5>
                  </v-col>
                  <v-col class="pa-0 pr-10" cols="6">
                    <h3 class="text-right">${{ sumaTotHaber }}</h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>
    </v-hover>
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title primary-title> Generando PDF </v-card-title>
          <v-card-text>
            <div class="text-center">
              <v-progress-linear
                indeterminate
                color="teal"
                height="10"
                rounded
                striped
              ></v-progress-linear>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout" dark color="green">
        <h3>{{ texto_snackbar }}</h3>
      </v-snackbar>
    </div>
    <div>
      <EditLibroAsiento
        ref="refFormEditLibroAsiento"
        :DatosLibro="voucher"
        @getValues="getValues"
        @getVoucher="getVoucher"
      />
    </div>
    <div>
      <EditFacturaAsiento
        ref="refDialogEditFactura"
        :voucher="voucher"
        @snackbarVoucher="snackbarVoucher"
      />
    </div>
    <SnackbarConfirm
      :messageSnackbar="messageSnackbar"
      @successResponse="openConfirm"
      ref="refSnackbarAnular"
    ></SnackbarConfirm>
  </v-container>
</template>

<script>
import GenerarPdf from "../../service/api/generarPdf/index";
import LibroDiarioService from "../../service/api/libroDiario/index";
import EditLibroAsiento from "./dialog/EditLibroAsiento";
import EditFacturaAsiento from "./../Inicio/dialog/EditaFactura";
import SnackbarConfirm from "../../components/Snackbar/SnackbarConfirm";
export default {
  name: "LibroDiario",
  components: {
    EditLibroAsiento,
    SnackbarConfirm,
    EditFacturaAsiento,
  },
  data() {
    return {
      cargandoFiltros: false,
      dialog: false,
      openPdf: 0,
      snackbar: "",
      texto_snackbar: "",
      timeout: 2000,
      total_debe: 0,
      total_haber: 0,
      switch1: true,
      filter_date: [],
      datos: [],
      asientos_debe: [],
      asientos_haber: [],
      headers: [
        { text: "Código Cuenta", value: "cod_cuenta" },
        { text: "Valor", value: "valor" },
      ],
      panel: true,
      date1: null,
      menu1: false,
      date2: null,
      menu2: false,
      sumaTotDebe: 0,
      sumaTotHaber: 0,
      load: false,
      voucher: 0,
      messageSnackbar: "",
    };
  },
  created() {
    this.obtenerFechaFiltros();
    this.getValues();
  },
  methods: {
    successResponse(voucher) {
      this.getValues();
      this.getVoucher(voucher);
    },
    errorResponse(response) {
      this.BuscarCuenta(this.cod_cuenta.id);
      alert("Error al crear");
      console.log("error: ", response);
    },
    obtenerFechaFiltros() {
      let fecha = new Date(this.$store.state.mes);
      let primer_dia = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 1)
        .toISOString()
        .substr(0, 10);
      let ultimo_dia = new Date(fecha.getFullYear(), fecha.getMonth() + 2, 0)
        .toISOString()
        .substr(0, 10);
      this.date1 = primer_dia;
      this.date2 = ultimo_dia;
    },
    async getValues() {
      let empresa = parseInt(this.$store.state.empresa);
      let response = await LibroDiarioService.fetchAll(
        empresa,
        this.date1,
        this.date2
      );
      this.datos = response.libro_lista;
      this.sumaTotDebe = this.formatoNumeroMiles(response.suma_debe);
      this.sumaTotHaber = this.formatoNumeroMiles(response.suma_haber);
      // this.date1 = new Date().toISOString().substr(0, 10);
      // this.date2 = new Date().toISOString().substr(0, 10);
    },
    async getVoucher(voucher) {
      if (voucher) {
        this.load = true;
        let empresa = parseInt(this.$store.state.empresa);
        let response = await LibroDiarioService.fetchByVoucher(
          voucher,
          empresa
        );
        let debe = 0;
        let haber = 0;
        response.map((resp) => {
          if (resp.debe_haber == 1) {
            debe += parseInt(resp.valor);
          } else {
            haber += parseInt(resp.valor);
          }
          resp.valorformato = this.formatoNumeroMiles(resp.valor);
          return resp;
        });
        this.total_debe = this.formatoNumeroMiles(debe);
        this.total_haber = this.formatoNumeroMiles(haber);
        this.asientos_debe = response.filter((resp) => {
          return resp.debe_haber == 1;
        });
        this.asientos_haber = response.filter((resp) => {
          return resp.debe_haber == 2;
        });
        this.load = false;
      }
    },
    obtenerTodos() {
      this.cargandoFiltros = true
      let fecha = new Date(this.$store.state.mes);
      let primer_dia = new Date(fecha.getFullYear(), "00", "01")
        .toISOString()
        .substr(0, 10);
      let ultimo_dia = new Date(fecha.getFullYear(), "11", "31")
        .toISOString()
        .substr(0, 10);
      this.date1 = primer_dia;
      this.date2 = ultimo_dia;
      this.openPdf = 1;
      this.cargandoFiltros = false
      this.getValues();
    },
    async filtroLibro(date1, date2) {
      this.cargandoFiltros = true
      this.openPdf = 2;
      let empresa = parseInt(this.$store.state.empresa);
      let response = await LibroDiarioService.fetchBydate(
        date1,
        date2,
        empresa
      );
      this.datos = response.libro_lista;
      this.sumaTotDebe = this.formatoNumeroMiles(response.suma_debe);
      this.sumaTotHaber = this.formatoNumeroMiles(response.suma_haber);
      this.cargandoFiltros = false
    },
    async LibroDiarioPDF() {
      this.dialog = true;
      let data = {};
      data.tipo_factura = "todos";
      data.empresa = parseInt(this.$store.state.empresa);
      data.usuario = parseInt(this.$store.state.user);
      data.desde = this.date1;
      data.hasta = this.date2;
      if (this.openPdf == 1) {
        data.desde = this.date1;
        data.hasta = this.date2;
      }
      if (data) {
        let response = await GenerarPdf.libroDiarioFactura(data);
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        this.dialog = false;
        return window.open(fileURL);
      }
    },
    EditLibro(voucher, tipocuenta) {
      if (tipocuenta == "asiento") {
        this.$refs.refFormEditLibroAsiento.openDialog = true;
      } else {
        this.$refs.refDialogEditFactura.openDialog = true;
      }
      this.voucher = voucher;
    },
    DeleteLibro(voucher) {
      this.$refs.refSnackbarAnular.openSnackbar = true;
      this.messageSnackbar = `¿Desea anular voucher: ${voucher}?`;
      this.voucher = voucher;
    },
    async openConfirm() {
      let anulado = {};
      for (let i = 0; i < this.datos.length; i++) {
        if (this.datos[i].voucher == this.voucher) {
          if (this.datos[i].estado == 1) {
            anulado.estado = 0;
            anulado.id_empresa = parseInt(this.$store.state.empresa);
          } else {
            anulado.estado = 1;
            anulado.id_empresa = parseInt(this.$store.state.empresa);
          }
        }
      }
      let response = await LibroDiarioService.cancel(this.voucher, anulado);
      if (response.status === 200) {
        this.$refs.refSnackbarAnular.openSnackbar = false;
        this.filtroLibro(this.date1, this.date2);
      } else this.errorResponse(response);
    },
    async UndoLibro(voucher) {
      this.$refs.refSnackbarAnular.openSnackbar = true;
      this.messageSnackbar = `¿Desea recuperar voucher: ${voucher}?`;
      this.voucher = voucher;
    },
    snackbarVoucher(voucher) {
      this.successResponse(voucher[0].voucher);
      this.snackbar = true;
      this.texto_snackbar = `Voucher ${voucher[0].voucher}, Editado Exitosamente`;
    },
    formatoNumeroMiles(number) {
      if (number || number > 0) {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = "$1.";
        let arr = number.toString().split(".");
        arr[0] = arr[0].replace(exp, rep);
        return arr[1] ? arr.join(".") : arr[0];
      } else return 0;
    },
  },
};
</script>
